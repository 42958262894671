import { useState, useRef } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Servicecard from "../components/Servicecard";
import spacesaving from "../assets/images/services/spacesaving.jpg";
import tvsuit from "../assets/images/services/tvunits.jpg";
import studytables from "../assets/images/services/studytable.jpg";
import falseceiling from "../assets/images/services/falseceiling.jpg";
import lights from "../assets/images/services/lights.jpg";
import wallpaper from "../assets/images/services/wallpaper.jpg";
import wallpaint from "../assets/images/services/wallpaint.jpg";
import bathroom from "../assets/images/services/bathroom.jpg";
import poojai from "../assets/images/services/poojairoom.jpg";
import foyer from "../assets/images/services/foyer.jpg";
import moving from "../assets/images/services/moving.jpg";
import kids from "../assets/images/services/kidsbedroom.jpg";
import wardrobe from "../assets/images/services/wardrobe.jpg";
import kitchen from "../assets/images/services/kitchen.jpg";
import crock from "../assets/images/services/crock.jpg";

const Ourservices = () => {
  const [showMore, setShowMore] = useState(false);
  const showMoreRef = useRef(null); 

  const handleShowMore = () => {
    setShowMore((prevShowMore) => {
      if (prevShowMore) {
        
        setTimeout(() => {
          showMoreRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 1000); 
      }
      return !prevShowMore;
    });
  };

  const services = [
    { image: spacesaving, servicename: "Space Saving Furniture" },
    { image: tvsuit, servicename: "TV Units" },
    { image: studytables, servicename: "Study Tables" },
    { image: falseceiling, servicename: "False Ceiling" },
    { image: lights, servicename: "Lights" },
    { image: wallpaper, servicename: "Wallpaper" },
    { image: wallpaint, servicename: "Wall Paint" },
    { image: bathroom, servicename: "Bathroom" },
    { image: poojai, servicename: "Poojai Unit" },
    { image: foyer, servicename: "Foyer Designs" },
    { image: moving, servicename: "Movable Furniture" },
    { image: kids, servicename: "Kids Bedroom" },
    { image: wardrobe, servicename: "Storage and Wardrobe" },
    { image: kitchen, servicename: "Modular Kitchen" },
    { image: crock, servicename: "Crockery Units" },
  ];


  const initialServices = services.slice(0, 8);

  const remainingServices = services.slice(8);

  return (
    <div className=" bg-slateblue px-16 py-10 max-lg:px-16 max-md:px-6">
        <h1 className="font-poppins font-extrabold text-white text-center text-3xl lg:text-4xl mb-10 max-sm:mb-4">
        Our Services
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 max-md:gap-4 gap-8">
        {initialServices.map((service, index) => (
          <Servicecard key={index} image={service.image} servicename={service.servicename} />
        ))}
        {showMore &&
          remainingServices.map((service, index) => (
            <Servicecard key={index} image={service.image} servicename={service.servicename} />
          ))}
      </div>

      <div ref={showMoreRef} className="text-center mt-12 animate-pulse">
        <div
          onClick={handleShowMore}
          className="inline-flex items-center cursor-pointer text-blue-600 hover:text-blue-800 transition duration-300"
        >
          {showMore ? (
            <>
              <span className="mr-2 text-lg text-white">Show Less</span>
              <AiOutlineUp className="text-white" size={24} />
            </>
          ) : (
            <>
              <span className="mr-2 text-lg text-white">Show More</span>
              <AiOutlineDown className="text-white" size={24} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ourservices;
