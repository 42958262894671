import { useState } from "react";
import { FaTimes } from "react-icons/fa"; // Close button

const ContactFormModal = ({ setIsModalOpen, prodname, prodimage }) => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "c0838771-bb2e-4880-a2b4-10ba689fbd4f"); // Web3Forms public access key

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      alert("Email sent successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="fixed inset-0 font-poppins flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl space-y-6">

        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-blue-700">Product Enquiry</h2>
          <button
            onClick={() => setIsModalOpen(false)}
            className="text-gray-500"
          >
            <FaTimes className="text-2xl" />
          </button>
        </div>

        <div className="flex justify-center">
          <img src={prodimage} alt="Product" className="w-full h-40 object-cover" />
        </div>

        <form onSubmit={onSubmit} className="grid grid-cols-2 gap-4">

          <div className="col-span-2">
            <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
              Subject
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              defaultValue={`Product Enquiry - ${prodname}`}
              readOnly
            />
          </div>

          <div>
            <label htmlFor="product" className="block text-sm font-medium text-gray-700">
              Product Name
            </label>
            <input
              type="text"
              name="product"
              id="product"
              className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              defaultValue={prodname}
              readOnly
            />
          </div>

          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              required
              className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Your Name"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              required
              className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Your Email"
            />
          </div>

          <div>
            <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">
              Mobile Number
            </label>
            <input
              type="tel"
              name="mobile"
              id="mobile"
              required
              className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Your Mobile Number"
            />
          </div>

          <div className="col-span-2">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              required
              className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Your Message"
              rows="4"
            ></textarea>
          </div>

          <div className="col-span-2">
            <button
              type="submit"
              className="w-full bg-black text-white p-3 rounded-md"
            >
              Send
            </button>
          </div>

          {result && (
            <p className="text-center text-sm mt-2 text-gray-600">{result}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactFormModal;
