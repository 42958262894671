import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import hamburger from "../assets/icons/hamburger.png";
import templogo from '../assets/images/templogo.png';
import { navLinks } from '../constants';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); 

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isLandingPage = location.pathname === '/';

  return (
    <header className="fixed font-poppins py-0 top-0 left-0 w-full  z-50 bg-white shadow-md">
      <nav className='py-5 max-sm:py-4 px-4 sm:px-4 lg:px-16'>
        <div className='container mx-auto flex justify-between items-center'>
          <a href='/'>
            <img src={templogo} alt='logo' className="w-48 h-auto max-sm:w-32 max-md:w-14 " />
          </a>
          {isLandingPage && ( 
            <div className='lg:hidden'>
              <img 
                src={hamburger}
                width={50}
                height={50}
                onClick={toggleMenu}
                className="cursor-pointer"
                alt="Menu"
              />
            </div>
          )}
          {isLandingPage && ( 
            <ul className='hidden lg:flex space-x-4 xl:space-x-8 text-sm xl:text-base'>
              {navLinks.map((item) => (
                <li key={item.label}>
                  <a href={item.href} className="flex font-poppins text-md items-center text-black hover:text-blue hover:underline">
                    <span className="ml-2">{item.label}</span>
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>

        {isOpen && isLandingPage && ( 
          <ul className='lg:hidden absolute top-full left-0 w-full bg-white shadow-lg p-4 space-y-4'>
            {navLinks.map((item) => (
              <li key={item.label}>
                <a 
                  href={item.href} 
                  className="text-black text-center justify-center hover:text-blue flex items-center"
                  onClick={toggleMenu} 
                >
                  <span className="ml-2">{item.label}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
