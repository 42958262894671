import { useState } from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaGlobe } from "react-icons/fa";

export default function Contact() {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "c0838771-bb2e-4880-a2b4-10ba689fbd4f"); // Web3Forms public access key

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="min-h-screen font-poppins bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-16">
      <div className="max-w-7xl w-full grid grid-cols-1 md:grid-cols-2 gap-8">
        
        {/* Form Section */}
        <div className="bg-white p-10 rounded-xl shadow-lg">
          <h2 className="text-3xl font-bold mb-6 text-blue-700">Contact Us</h2>
          <form onSubmit={onSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                required
                className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Your Name"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                required
                className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Your Email"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Mobile Number
              </label>
              <input
                type="phone"
                name="mobile"
                id="mobile"
                required
                className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Your Mobile"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Subject
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                required
                className="mt-1 p-3 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Subject"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium  text-gray-700">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                rows="4"
                required
                className="mt-1 p-3 block w-full shadow-sm h-24 sm:text-sm border-gray-300 rounded-md"
                placeholder="Your Message"
              ></textarea>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="w-full bg-black inline-flex justify-center py-3 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Submit
              </button>
            </div>
          </form>

          <div className="text-center mt-4">
            <span className="text-sm text-gray-700">{result}</span>
          </div>
        </div>

        <div className="bg-white p-10 rounded-xl shadow-lg space-y-6">
          <h2 className="text-3xl font-bold mb-6 text-blue-700">Contact Information</h2>
          <div className="space-y-4">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-blue-600 w-6 h-6 mr-3" />
              <div>
                <p className="font-semibold">Address:</p>
                <p>140, Vembu Avenue, Senthura Street</p>
                <p>Vadavalli, Coimbatore - 641041</p>
              </div>
            </div>

            <div className="flex items-center">
              <FaPhoneAlt className="text-blue-600 w-6 h-6 mr-3" />
              <div>
                <p className="font-semibold">Call:</p>
                <p>9629921146, 9578838127</p>
              </div>
            </div>

            <div className="flex items-center">
              <FaEnvelope className="text-blue-600 w-6 h-6 mr-3" />
              <div>
                <p className="font-semibold">Email:</p>
                <a href="mailto:wallandtint@gmail.com" className="text-blue-600">
                  wallandtint@gmail.com
                </a>
              </div>
            </div>

            <div className="flex items-center">
              <FaGlobe className="text-blue-600 w-6 h-6 mr-3" />
              <div>
                <p className="font-semibold">Website:</p>
                <a href="#" className="text-blue-600">www.wallandtint.com</a>
              </div>
            </div>

            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1022.9399150906811!2d76.90277226634154!3d11.018602564280624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85f78b7734c8b%3A0xb0f7a650b1551e16!2sWall%20%26%20Tint%20Interior%20Design%20Studio!5e0!3m2!1sen!2sin!4v1726431270439!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Google Maps"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
