// import about from "../assets/videos/about.mp4";

// const AboutUs = () => {
//   return (
//     <section className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
//       <div className="flex flex-col-reverse lg:flex-row max-w-7xl gap-14 justify-center items-center mx-auto">
//         <div className="flex justify-center w-full lg:w-auto">
//           <video
//             className="w-full lg:w-[600px] lg:h-[400px] rounded-lg shadow-lg bg-black transform transition duration-500 hover:scale-105"
//             autoPlay
//             loop
//             muted
//           >
//             <source src={about} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//         <div className="flex flex-col items-start lg:items-start text-center lg:text-left">
//           <div className="flex flex-row gap-2">
//           <h2 className="text-xl font-inknut font-bold text-black sm:text-4xl lg:text-3xl leading-tight">
//             About 
//           </h2><h1 className="text-xl font-inknut font-bold text-slateblue sm:text-4xl lg:text-3xl leading-tight">Wall & Tint</h1></div>
//           <p className="mt-4 font-inknut text-justify w-96 max-md:w-96 max-sm:w-96  text-md text-lg sm:text-xl lg:text-lg text-gray-600 mx-auto lg:mx-0">
//           Wall & Tint Interiors has been ceaseless since 2020, designing beautiful homes, cozy bedrooms, commercial spaces, 
//           and offices with unique designs. We design your dream considering not just beauty but comfort. Whether it is a bedroom 
//           to relax in after a hectic day, a theatre unit to binge-watch your favorite shows, a kitchen that supports your culinary 
//           experiments, or an office space to fuel your passion, we add magic to it. Once you sign in, your dream becomes our dream too.
//             </p>
//         </div>
//       </div>
//     </section>
//   );

import logo from "../assets/images/temlogow12.png";

const AboutUs = () => {
  return (
    <div className="px-4 sm:px-8 lg:px-32 max-sm:py-12 py-24">
      <h1 className="font-poppins font-extrabold text-left text-3xl lg:text-4xl mb-6" data-aos="fade-right">
        About Us
      </h1>
      <div className="flex flex-col justify-center lg:flex-row gap-12 lg:gap-16">
        <div className="lg:w-max flex justify-center flex-col items-center" data-aos="fade-right">
        <div className="lg:w-max mx-auto lg:mx-0" data-aos="fade-right">
          <img 
            src={logo} 
            alt="Company Logo" 
            className="w-full max-w-xs sm:max-w-sm md:max-w-md mb-6 lg:max-w-md object-contain mx-auto"
          />
        </div>
          <p className="font-poppins text-lg text-justify leading-relaxed">
            Wall & Tint Interiors has been ceaseless since 2020, designing beautiful homes, cozy bedrooms, commercial spaces, and offices with unique designs. <br /><br />
            We design your dream considering not just beauty but comfort. Whether it is a bedroom to relax in after a hectic day, a theatre unit to binge-watch your favorite shows, a kitchen that supports your culinary experiments, or an office space to fuel your passion, we add magic to it. Once you sign in, your dream becomes our dream too.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;








