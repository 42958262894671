import img_01 from '../assets/images/Projects/img_01.jpg';
import img_02 from '../assets/images/Projects/img_02.jpg';
import img_03 from '../assets/images/Projects/img_03.jpg';
import img_04 from '../assets/images/Projects/img_04.jpg';
import img_05 from '../assets/images/Projects/img_05.jpg';
import img_06 from '../assets/images/Projects/img_06.jpg';
import img_07 from '../assets/images/Projects/img_07.jpg';
import img_08 from '../assets/images/Projects/img_08.jpg';
import img_09 from '../assets/images/Projects/img_09.jpg';
import img_10 from '../assets/images/Projects/img_10.jpg';
import img_11 from '../assets/images/Projects/img_11.jpg';
import img_12 from '../assets/images/Projects/img_12.jpg';
import img_13 from '../assets/images/Projects/img_13.jpg';
import img_14 from '../assets/images/Projects/img_14.jpg';
import img_15 from '../assets/images/Projects/img_15.jpg';
import img_16 from '../assets/images/Projects/img_16.jpg';
import img_17 from '../assets/images/Projects/img_17.jpg';
import img_18 from '../assets/images/Projects/img_18.jpg';
import img_19 from '../assets/images/Projects/img_19.jpg';
import img_20 from '../assets/images/Projects/img_20.jpg';
import img_21 from '../assets/images/Projects/img_21.jpg';
import img_22 from '../assets/images/Projects/img_22.jpg';
import img_23 from '../assets/images/Projects/img_23.jpg';
import img_24 from '../assets/images/Projects/img_24.jpg';
import img_25 from '../assets/images/Projects/img_25.jpg';
import img_26 from '../assets/images/Projects/img_26.jpg';
import img_27 from '../assets/images/Projects/img_27.jpg';
import img_28 from '../assets/images/Projects/img_28.jpg';
import img_29 from '../assets/images/Projects/img_29.jpg';
import img_30 from '../assets/images/Projects/img_30.jpg';
import img_31 from '../assets/images/Projects/img_31.jpg';
import img_32 from '../assets/images/Projects/img_32.jpg';
import img_33 from '../assets/images/Projects/img_33.jpg';
import img_34 from '../assets/images/Projects/img_34.jpg';
import img_35 from '../assets/images/Projects/img_35.jpg';
import img_36 from '../assets/images/Projects/img_36.jpg';
import img_37 from '../assets/images/Projects/img_37.jpg';
import img_38 from '../assets/images/Projects/img_38.jpg';
import img_39 from '../assets/images/Projects/img_39.jpg';
import img_40 from '../assets/images/Projects/img_40.jpg';
import img_41 from '../assets/images/Projects/img_41.jpg';
import img_42 from '../assets/images/Projects/img_42.jpg';
import img_43 from '../assets/images/Projects/img_43.jpg';
import img_44 from '../assets/images/Projects/img_44.jpg';
import img_45 from '../assets/images/Projects/img_45.jpg';
import img_46 from '../assets/images/Projects/img_46.jpg';
import img_47 from '../assets/images/Projects/img_47.jpg';
import img_48 from '../assets/images/Projects/img_48.jpg';
import img_49 from '../assets/images/Projects/img_49.jpg';
import img_50 from '../assets/images/Projects/img_50.jpg';
import img_51 from '../assets/images/Projects/img_51.jpg';
import img_52 from '../assets/images/Projects/img_52.jpg';
import img_53 from '../assets/images/Projects/img_53.jpg';
import img_54 from '../assets/images/Projects/img_54.jpg';
import img_55 from '../assets/images/Projects/img_55.jpg';
import img_56 from '../assets/images/Projects/img_56.jpg';
import img_57 from '../assets/images/Projects/img_57.jpg';
import img_58 from '../assets/images/Projects/img_58.jpg';
import img_59 from '../assets/images/Projects/img_59.jpg';
import img_60 from '../assets/images/Projects/img_60.jpg';
import img_61 from '../assets/images/Projects/img_61.jpg';
import img_62 from '../assets/images/Projects/img_62.jpg';
import img_63 from '../assets/images/Projects/img_63.jpg';
import img_64 from '../assets/images/Projects/img_64.jpg';
import img_65 from '../assets/images/Projects/img_65.jpg';
import img_66 from '../assets/images/Projects/img_66.jpg';
import img_67 from '../assets/images/Projects/img_67.jpg';
import img_68 from '../assets/images/Projects/img_68.jpg';
import img_69 from '../assets/images/Projects/img_69.jpg';
import img_70 from '../assets/images/Projects/img_70.jpg';
import img_71 from '../assets/images/Projects/img_71.jpg';
import img_72 from '../assets/images/Projects/img_72.jpg';

import { Link } from 'react-router-dom';
const photos = [
  { src: img_01 },
  { src: img_02 },
  { src: img_03 },
  { src: img_04 },
  { src: img_05 },
  { src: img_06 },
  { src: img_07 },
  { src: img_08 },
  { src: img_09 },
  { src: img_10 },
  { src: img_11 },
  { src: img_12 },
  { src: img_13 },
  { src: img_14 },
  { src: img_15 },
  { src: img_16 },
  { src: img_17 },
  { src: img_18 },
  { src: img_19 },
  { src: img_20 },
  { src: img_21 },
  { src: img_22 },
  { src: img_23 },
  { src: img_24 },
  { src: img_25 },
  { src: img_26 },
  { src: img_27 },
  { src: img_28 },
  { src: img_29 },
  { src: img_30 },
  { src: img_31 },
  { src: img_32 },
  { src: img_33 },
  { src: img_34 },
  { src: img_35 },
  { src: img_36 },
  { src: img_37 },
  { src: img_38 },
  { src: img_39 },
  { src: img_40 },
  { src: img_41 },
  { src: img_42 },
  { src: img_43 },
  { src: img_44 },
  { src: img_45 },
  { src: img_46 },
  { src: img_47 },
  { src: img_48 },
  { src: img_49 },
  { src: img_50 },
  { src: img_51 },
  { src: img_52 },
  { src: img_53 },
  { src: img_54 },
  { src: img_55 },
  { src: img_56 },
  { src: img_57 },
  { src: img_58 },
  { src: img_59 },
  { src: img_60 },
  { src: img_61 },
  { src: img_62 },
  { src: img_63 },
  { src: img_64 },
  { src: img_65 },
  { src: img_66 },
  { src: img_67 },
  { src: img_68 },
  { src: img_69 },
  { src: img_70 },
  { src: img_71 },
  { src: img_72 },
];



const FullGalleryComponent = () => {
  return (
    
    <div className="container font-poppins mt-11 mx-auto py-8 ">

      <div className="flex bg-gradient-to-r from-amber-400 to-sandybrown mb-2 mt-2 justify-center items-center h-40 w-auto" data-dos="fade-up">
        <h1 className="flex items-center justify-center text-white font-bold text-4xl" data-dos="fade-right">
            Our Projects
        </h1>
    </div>
    <div className="relative z-10 mt-4 max-sm:px-6 px-16 mb-4">
            <nav className="text-black text-sm font-bold">
              <ul className="flex items-center space-x-2">
                <li>
                  <Link to="/" className="hover:underline">
                    Home
                  </Link>
                </li>
                <li>{`>`}</li>
                <li>
                  <Link to="/full-gallery" className="hover:underline">
                    Our Projects
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 bg-lightblack py-10 gap-4 max-sm:px-6 px-16">
        {photos.map((photo, index) => (
            <div
            key={index}
            className="relative overflow-hidden group" 
            >
            <img
                src={photo.src}
                alt={`Gallery item ${index}`}
                className="w-full h-full object-cover transition-all duration-500 group-hover:scale-105" 
                style={{ maxHeight: '300px' }}
            />
            <div className="absolute inset-0 bg-gradient-to-r from-transparent to-white opacity-0 group-hover:opacity-30 transition-opacity duration-500"></div> {/* Shine effect */}
            <div className="absolute inset-0 shine-effect"></div> 
            </div>
        ))}
        </div>
    </div>
  );
};

export default FullGalleryComponent;
