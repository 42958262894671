import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import AOS from 'aos'; 
import 'aos/dist/aos.css'; 
import { Header } from "./Header";
import AboutUs from "../sections/AboutUs";
import HowWeWork from "../sections/HomeWork";
import Ourservices from "../sections/Ourservices";
import GalleryComponent from "./Gallery";
import FullGalleryComponent from "./FullGallery";
import AllProducts from "../sections/AllProducts";
import Products from "./Products";
import ProductDetails from "./ProductDetails";
import Testimonials from "../sections/Testimonials";
import Contact from "./Contact";
import Footer from "./Footer";
import NewHero from "../sections/NewHero";

function ScrollToSection() {
  const location = useLocation();

  useEffect(() => {

    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {

    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Router>
      <ScrollToSection />
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <section id="/" data-aos="fade-up"> 
                <NewHero />
              </section>
              <section id="about-us" className={`scroll-mt-24`}  data-aos="fade-up"> 
                <AboutUs />
              </section>
              <section id="how-we-work" data-aos="fade-up"> 
                <HowWeWork />
              </section>
              <section id="products" className={`scroll-mt-24 padding-b padding-t`}  data-aos="fade-up"> 
                <Products />
              </section>
              <section id="services" className={`scroll-mt-36`} data-aos="fade-up"> 
                <Ourservices />
              </section>
              <section id="projects" className={`scroll-mt-36`} data-aos="fade-up"> 
                <GalleryComponent />
              </section>
              <section id="testimonials" data-aos="fade-up"> 
                <Testimonials />
              </section>
              <section id="contact" className={`scroll-mt-24 padding-t`} data-aos="fade-up"> 
                <Contact />
              </section>
            </>
          }
        />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/full-gallery" element={<FullGalleryComponent />} />
        <Route path="/all-products" element={<AllProducts />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
