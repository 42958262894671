import { useState, useEffect, useRef } from 'react';
import img1 from '../assets/images/img1.png';
import img2 from '../assets/images/img2.png';
import img3 from '../assets/images/img3.png';
import img4 from '../assets/images/img4.png';

const HeroSlider = () => {
  const contentData = [
    {
      image: img1,
      color: '#4A6382', 
      heading: 'Premium Materials',
      text: 'Elevate your space with premium materials that blend luxury and durability, creating interiors that stand the test of time. Discover the essence of refined design in every detail.'
    },
    {
      image: img2,
      color: '#A68768', 
      heading: '10 Years Warranty',
      text: 'Enjoy peace of mind with our 10-year warranty, ensuring your interiors maintain their premium quality and craftsmanship for years to come.'
    },
    {
      image: img3,
      color: '#1E1E1E', 
      heading: 'Completion in 30 Working Days',
      text: 'Transform your space swiftly with our efficient process, delivering beautifully designed interiors in just 30 working days.'
    },
    {
      image: img4,
      color: '#071739', 
      heading: 'Life-long Service Support',
      text: 'Experience unmatched reliability with our lifetime service support, keeping your interiors in perfect condition long after completion.'
    }
  ];

  const [currentContent, setCurrentContent] = useState(contentData[0]);
  const [transition, setTransition] = useState(false);

  const intervalRef = useRef(null);

  useEffect(() => {
    const rotateContent = () => {
      const currentIndex = contentData.findIndex(content => content.image === currentContent.image);
      const nextIndex = (currentIndex + 1) % contentData.length;
      setTransition(true);

      setTimeout(() => {
        setCurrentContent(contentData[nextIndex]);
        setTransition(false);
      }, 1000); 
    };

    intervalRef.current = setInterval(rotateContent, 3000);

    return () => clearInterval(intervalRef.current);
  }, [currentContent]);

  return (
    <div className="relative rounded-lg h-full overflow-hidden">
      <div className='w-96 h-96'>
        <div className="absolute top-0 left-0 h-full w-full flex">
          <img 
            src={currentContent.image} 
            className={`h-full w-full object-cover absolute transition-transform duration-1000 ${transition ? 'translate-x-[-100%]' : 'translate-x-0'}`}
            alt="Hero Background Image"
          />
          <img 
            src={contentData[(contentData.findIndex(content => content.image === currentContent.image) + 1) % contentData.length].image} 
            className={`h-full w-full object-cover absolute transition-transform duration-1000 ${transition ? 'translate-x-0' : 'translate-x-[100%]'}`}
            alt="Next Hero Background Image"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;
