
import { FaHome, FaInfoCircle, FaBriefcase, FaEnvelope, FaImages } from 'react-icons/fa';

export const navLinks = [
  { href: '#', label: 'Home', icon: <FaHome /> },       
  { href: '#about-us', label: 'About Us', icon: <FaInfoCircle /> }, 
  { href: '#products', label: 'Products', icon: <FaBriefcase /> },
  { href: '#services', label: 'Services', icon: <FaBriefcase /> },  
  { href: '#projects', label: 'Projects', icon: <FaImages /> },       
  { href: '#contact', label: 'Contact Us', icon: <FaEnvelope /> },  
];

  