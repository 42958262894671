import testim from "../assets/images/testimonials.jpg";
import { motion } from 'framer-motion';
import { FaStar, FaUser } from 'react-icons/fa'; 

const testimonials = [
  {
    text: "I am really grateful to Wall & Tint Interior Design Studio who helped us transform our storeroom to a beautiful study room for our kids. They were really patient enough and listened to all our queries and suggestions. They helped us with each and everything throughout the process. I would strongly suggest them for all your interior works.",
    name: "Swarnalatha Veerappan",
    city: "Vadavalli, Coimbatore",
    rating: 4, 
  },
  {
    text: "Hello Wall & Tint team. We have recently got our home designed by Wall & Tint Design Studio. I should say that they have done a wonderful job. They listen to our requirements patiently irrespective of multiple change requests from us while during the design phase. Thank you so much for everything. All your suggestions made our home a dream space now. You have done a great job for us. Also, thank you Suresh for meticulously executing the project professionally and for the timely delivery. Will surely recommend Wall & Tint to all my friends and family.",
    name: "Muthu",
    city: "Somiyampalayam, Coimbatore",
    rating: 4, 
  },
  {
    text: "Thank you Suresh. We had such a wonderful experience with Wall & Tint! From the very first meeting, they made us feel comfortable and understood our needs perfectly. The designers were creative and patient, and the final result is a home that truly reflects our style. We couldn’t be happier.",
    name: "Kumaran",
    city: "Edayarpalayam, Coimbatore",
    rating: 4,
  },
];

const Testimonials = () => {
  return (
    <section className="relative font-poppins bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 py-12 px-16 max-sm:px-6 lg:px-16">
      <div className="absolute inset-0 overflow-hidden">
        <img
          src={testim}
          alt="Background"
          className="w-full h-full object-cover opacity-60"
        />
        <div className="absolute inset-0 bg-midnightblue opacity-70" />
      </div>
      <div className="relative z-10 text-center">
        <h2 className="text-3xl font-poppins font-extrabold text-white mb-6">
          What our clients say about us
        </h2>
        <div className="mt-2 mx-auto border-t-4 border-yellow-400 w-16" />
        <div className="mt-8 flex flex-wrap justify-center gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-start text-left relative overflow-hidden transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl max-w-md w-full"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="flex items-center mb-4">
                <div className="w-20 h-20 rounded-full bg-gray-200 mr-4 flex items-center justify-center overflow-hidden">
                  <FaUser className="text-gray-400 w-full h-full" /> 
                </div>
                <div className="flex flex-col justify-center">
                  <h5 className="text-lg font-semibold text-gray-900">{testimonial.name}</h5>
                  <p className="text-sm text-gray-600">{testimonial.city}</p>
                </div>
              </div>
              <p className="text-gray-700 mb-4 text-sm">{testimonial.text}</p>
              <div className="flex items-center mb-4">
                {Array.from({ length: 5 }, (_, i) => (
                  <FaStar
                    key={i}
                    className={`text-yellow-400 ${i < testimonial.rating ? "text-yellow-400" : "text-gray-300"}`}
                  />
                ))}
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-white via-transparent opacity-10" />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
