import Prodcard from "../components/ProdCard";
// Book shelf
import thumbnail1 from "../assets/images/Products/Book shelf/thumbnail1.jpg";
import img1 from "../assets/images/Products/Book shelf/img1.jpg";
import img2 from "../assets/images/Products/Book shelf/img2.jpg";
import img3 from "../assets/images/Products/Book shelf/img3.jpg";

// Chest of drawers
import thumbnail2 from "../assets/images/Products/Chest of drawers/thumbnail2.jpg";
import img4 from "../assets/images/Products/Chest of drawers/img4.jpg";
import img5 from "../assets/images/Products/Chest of drawers/img5.jpg";
import img6 from "../assets/images/Products/Chest of drawers/img6.jpg";
import img7 from "../assets/images/Products/Chest of drawers/img7.jpg";
import img8 from "../assets/images/Products/Chest of drawers/img8.jpg";
import img9 from "../assets/images/Products/Chest of drawers/img9.jpg";
import img10 from "../assets/images/Products/Chest of drawers/img10.jpg";
import img11 from "../assets/images/Products/Chest of drawers/img11.jpg";

// Crockery unit
import thumbnail3 from "../assets/images/Products/Crockery unit/thumbnail3.jpg";
import img12 from "../assets/images/Products/Crockery unit/img12.jpg";
import img13 from "../assets/images/Products/Crockery unit/img13.jpg";

// Dressing Unit
import thumbnail4 from "../assets/images/Products/Dressing Unit/thumbnail4.jpg";
import img14 from "../assets/images/Products/Dressing Unit/img14.jpg";
import img15 from "../assets/images/Products/Dressing Unit/img15.jpg";

// Foyer unit
import thumbnail5 from "../assets/images/Products/Foyer unit/thumbnail5.jpg";
import img16 from "../assets/images/Products/Foyer unit/img16.jpg";
import img17 from "../assets/images/Products/Foyer unit/img17.jpg";

// Kitchen Cabinet
import thumbnail6 from "../assets/images/Products/Kitchen Cabinet/thumbnail6.jpg";
import img18 from "../assets/images/Products/Kitchen Cabinet/img18.jpg";

// Kitchen Pantry
import thumbnail7 from "../assets/images/Products/Kitchen Pantry/thumbnail7.jpg";
import img19 from "../assets/images/Products/Kitchen Pantry/img19.jpg";
import img20 from "../assets/images/Products/Kitchen Pantry/img20.jpg";
import img21 from "../assets/images/Products/Kitchen Pantry/img21.jpg";

// Kitchen pantry unit
import thumbnail8 from "../assets/images/Products/Kitchen pantry unit/thumbnail8.jpg";
import img22 from "../assets/images/Products/Kitchen pantry unit/img22.jpg";
import img23 from "../assets/images/Products/Kitchen pantry unit/img23.jpg";
import img24 from "../assets/images/Products/Kitchen pantry unit/img24.jpg";
import img25 from "../assets/images/Products/Kitchen pantry unit/img25.jpg";
import img26 from "../assets/images/Products/Kitchen pantry unit/img26.jpg";
import img27 from "../assets/images/Products/Kitchen pantry unit/img27.jpg";
import img28 from "../assets/images/Products/Kitchen pantry unit/img28.jpg";
import img29 from "../assets/images/Products/Kitchen pantry unit/img29.jpg";
import img30 from "../assets/images/Products/Kitchen pantry unit/img30.jpg";
import img31 from "../assets/images/Products/Kitchen pantry unit/img31.jpg";
import img32 from "../assets/images/Products/Kitchen pantry unit/img32.jpg";
import img33 from "../assets/images/Products/Kitchen pantry unit/img33.jpg";
import img34 from "../assets/images/Products/Kitchen pantry unit/img34.jpg";

// Kitchen side by side pantry
import thumbnail9 from "../assets/images/Products/Kitchen side by side pantry/thumbnail9.jpg";
import img35 from "../assets/images/Products/Kitchen side by side pantry/img35.jpg";
import img36 from "../assets/images/Products/Kitchen side by side pantry/img36.jpg";
import img37 from "../assets/images/Products/Kitchen side by side pantry/img37.jpg";

// Kitchen Wall Mounted Cabinet
import thumbnail10 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/thumbnail10.jpg";
import img38 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/img38.jpg";
import img39 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/img39.jpg";
import img40 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/img40.jpg";
import img41 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/img41.jpg";

// Multipurpose Shelf
import thumbnail11 from "../assets/images/Products/Multipurpose Shelf/thumbnail11.jpg";
import img42 from "../assets/images/Products/Multipurpose Shelf/img42.jpg";

// Pooja unit
import thumbnail12 from "../assets/images/Products/Pooja unit/thumbnail12.jpg";
import img43 from "../assets/images/Products/Pooja unit/img43.jpg";
import img44 from "../assets/images/Products/Pooja unit/img44.jpg";
import img45 from "../assets/images/Products/Pooja unit/img45.jpg";

// Shoe Rack
import thumbnail13 from "../assets/images/Products/Shoe Rack/thumbnail13.jpg";
import img46 from "../assets/images/Products/Shoe Rack/img46.jpg";
import img47 from "../assets/images/Products/Shoe Rack/img47.jpg";
import img48 from "../assets/images/Products/Shoe Rack/img48.jpg";
import img49 from "../assets/images/Products/Shoe Rack/img49.jpg";
import img50 from "../assets/images/Products/Shoe Rack/img50.jpg";
import img51 from "../assets/images/Products/Shoe Rack/img51.jpg";

// TV unit
import thumbnail14 from "../assets/images/Products/Tv unit/thumbnail14.jpg";
import img52 from "../assets/images/Products/Tv unit/img52.jpg";
import img53 from "../assets/images/Products/Tv unit/img53.jpg";
import img54 from "../assets/images/Products/Tv unit/img54.jpg";

// Wall floating shelves
import thumbnail15 from "../assets/images/Products/Wall floating shelves/thumbnail15.jpg";
import img55 from "../assets/images/Products/Wall floating shelves/img55.jpg";
import img56 from "../assets/images/Products/Wall floating shelves/img56.jpg";
import img57 from "../assets/images/Products/Wall floating shelves/img57.jpg";
import img58 from "../assets/images/Products/Wall floating shelves/img58.jpg";
import img59 from "../assets/images/Products/Wall floating shelves/img59.jpg";

import { Link } from "react-router-dom";

const AllProducts = () => {

    const products = [
        {
            prodname: "Book Shelf",
            thumbnail: thumbnail1,
            images: [img1, img2, img3, img4]
        },
        {
            prodname: "Chest of Drawers",
            thumbnail: thumbnail2,
            images: [img5, img6, img7, img8, img9, img10, img11]
        },
        {
            prodname: "Crockery Unit",
            thumbnail: thumbnail3,
            images: [img12, img13]
        },
        {
            prodname: "Dressing Unit",
            thumbnail: thumbnail4,
            images: [img14, img15]
        },
        {
            prodname: "Foyer Unit",
            thumbnail: thumbnail5,
            images: [img16, img17]
        },
        {
            prodname: "Kitchen Cabinet",
            thumbnail: thumbnail6,
            images: [img18]
        },
        {
            prodname: "Kitchen Pantry",
            thumbnail: thumbnail7,
            images: [img19, img20, img21]
        },
        {
            prodname: "Kitchen Pantry Unit",
            thumbnail: thumbnail8,
            images: [img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34]
        },
        {
            prodname: "Kitchen Side by Side Pantry",
            thumbnail: thumbnail9,
            images: [img35, img36, img37]
        },
        {
            prodname: "Kitchen Wall Mounted Cabinet",
            thumbnail: thumbnail10,
            images: [img38, img39, img40, img41]
        },
        {
            prodname: "Multipurpose Shelf",
            thumbnail: thumbnail11,
            images: [img42]
        },
        {
            prodname: "Pooja Unit",
            thumbnail: thumbnail12,
            images: [img43, img44, img45]
        },
        {
            prodname: "Shoe Rack",
            thumbnail: thumbnail13,
            images: [img46, img47, img48, img49, img50, img51]
        },
        {
            prodname: "TV Unit",
            thumbnail: thumbnail14,
            images: [img52, img53, img54]
        },
        {
            prodname: "Wall Floating Shelves",
            thumbnail: thumbnail15,
            images: [img55, img56, img57, img58, img59]
        }
    ];
    
    return (
        <div className="products-container font-poppins mt-11 pt-6" data-dos="fade-up">

          <div className="flex bg-gradient-to-r from-slateblue to-midnightblue mb-4 mt-4 justify-center items-center h-32 sm:h-40 w-auto">
            <h1 className="text-white font-bold text-2xl sm:text-3xl lg:text-4xl text-center" data-dos="fade-right">
              Our Products
            </h1>
          </div>
          <div className="relative z-10 mt-4 max-sm:px-6 px-16 mb-4">
            <nav className="text-black text-sm font-bold">
              <ul className="flex items-center space-x-2">
                <li>
                  <Link to="/" className="hover:underline">
                    Home
                  </Link>
                </li>
                <li>{`>`}</li>
                <li>
                  <Link to="/all-products" className="hover:underline">
                    Our Products
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className="px-4 sm:px-8 lg:px-16 py-8 sm:py-12 bg-sandybrown grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6" data-dos="fade-up">
            {products.map((product, index) => (
              <Prodcard
                key={index}
                prodname={product.prodname}
                thumbnail={product.thumbnail}
                images={product.images}
              />
            ))}
          </div>
        </div>
      );
      
};

export default AllProducts;
