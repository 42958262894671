import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Prodcard from "./ProdCard";
import thumbnail1 from "../assets/images/Products/Book shelf/thumbnail1.jpg";
import img1 from "../assets/images/Products/Book shelf/img1.jpg";
import img2 from "../assets/images/Products/Book shelf/img2.jpg";
import img3 from "../assets/images/Products/Book shelf/img3.jpg";
import thumbnail2 from "../assets/images/Products/Chest of drawers/thumbnail2.jpg";
import img4 from "../assets/images/Products/Chest of drawers/img4.jpg";
import img5 from "../assets/images/Products/Chest of drawers/img5.jpg";
import img6 from "../assets/images/Products/Chest of drawers/img6.jpg";
import img7 from "../assets/images/Products/Chest of drawers/img7.jpg";
import img8 from "../assets/images/Products/Chest of drawers/img8.jpg";
import img9 from "../assets/images/Products/Chest of drawers/img9.jpg";
import img10 from "../assets/images/Products/Chest of drawers/img10.jpg";
import img11 from "../assets/images/Products/Chest of drawers/img11.jpg";
import thumbnail3 from "../assets/images/Products/Crockery unit/thumbnail3.jpg";
import img12 from "../assets/images/Products/Crockery unit/img12.jpg";
import img13 from "../assets/images/Products/Crockery unit/img13.jpg";
import thumbnail4 from "../assets/images/Products/Dressing Unit/thumbnail4.jpg";
import img14 from "../assets/images/Products/Dressing Unit/img14.jpg";
import img15 from "../assets/images/Products/Dressing Unit/img15.jpg";
import thumbnail5 from "../assets/images/Products/Foyer unit/thumbnail5.jpg";
import img16 from "../assets/images/Products/Foyer unit/img16.jpg";
import img17 from "../assets/images/Products/Foyer unit/img17.jpg";

const Products = () => {
  const navigate = useNavigate();

  const products = [
    {
      prodname: "Book Shelf",
      thumbnail: thumbnail1,
      images: [img1, img2, img3]
    },
    {
      prodname: "Chest of Drawers",
      thumbnail: thumbnail2,
      images: [img4, img5, img6, img7, img8, img9, img10, img11]
    },
    {
      prodname: "Crockery Unit",
      thumbnail: thumbnail3,
      images: [img12, img13]
    },
    {
      prodname: "Dressing Unit",
      thumbnail: thumbnail4,
      images: [img14, img15]
    },
    {
      prodname: "Foyer Unit",
      thumbnail: thumbnail5,
      images: [img16, img17]
    },
  ];

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  const handleViewAllClick = () => {

    window.scrollTo(0, 0);


    navigate('/all-products');
  };

  const handleProductClick = (product) => {
    navigate('/product-details', { state: { product } });
  };

  return (
    <div className="products-container font-poppins bg-sandybrown px-4 sm:px-6 lg:px-16 py-12">
      <h1 className="font-poppins font-extrabold text-white text-center text-3xl lg:text-4xl mb-10 max-md:mb-4" data-dos="fade-right">
        Our Products
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
        {products.slice(0, 5).map((product, index) => (
          <Prodcard
            key={index}
            prodname={product.prodname}
            thumbnail={product.thumbnail}
            images={product.images}
            onClick={() => handleProductClick(product)} 
          />
        ))}
      </div>

      <div className="mt-10 text-center">
        <button
          onClick={handleViewAllClick}
          className="border-2 border-white text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg hover:bg-blue-700 transition"
        >
          View All Products
        </button>
      </div>
    </div>
  );
};

export default Products;
