import img_01 from '../assets/images/Projects/img_01.jpg';
import img_02 from '../assets/images/Projects/img_02.jpg';
import img_03 from '../assets/images/Projects/img_03.jpg';
import img_04 from '../assets/images/Projects/img_04.jpg';
import img_05 from '../assets/images/Projects/img_05.jpg';
import img_06 from '../assets/images/Projects/img_06.jpg';
import img_07 from '../assets/images/Projects/img_07.jpg';
import img_08 from '../assets/images/Projects/img_08.jpg';
import img_09 from '../assets/images/Projects/img_09.jpg';
import img_10 from '../assets/images/Projects/img_10.jpg';
import img_11 from '../assets/images/Projects/img_11.jpg';
import img_12 from '../assets/images/Projects/img_12.jpg';
import img_13 from '../assets/images/Projects/img_13.jpg';
import img_14 from '../assets/images/Projects/img_14.jpg';
import img_15 from '../assets/images/Projects/img_15.jpg';
import img_16 from '../assets/images/Projects/img_16.jpg';
import img_17 from '../assets/images/Projects/img_17.jpg';
import img_18 from '../assets/images/Projects/img_18.jpg';
import img_19 from '../assets/images/Projects/img_19.jpg';
import img_20 from '../assets/images/Projects/img_20.jpg';
import img_21 from '../assets/images/Projects/img_21.jpg';
import img_22 from '../assets/images/Projects/img_22.jpg';
import img_23 from '../assets/images/Projects/img_23.jpg';
import img_24 from '../assets/images/Projects/img_24.jpg';
import img_25 from '../assets/images/Projects/img_25.jpg';
import img_26 from '../assets/images/Projects/img_26.jpg';
import img_27 from '../assets/images/Projects/img_27.jpg';
import img_28 from '../assets/images/Projects/img_28.jpg';
import img_29 from '../assets/images/Projects/img_29.jpg';
import img_30 from '../assets/images/Projects/img_30.jpg';
import { useNavigate } from "react-router-dom";


const photos = [
  { src: img_01 },
  { src: img_02 },
  { src: img_03 },
  { src: img_04 },
  { src: img_05 },
  { src: img_06 },
  { src: img_07 },
  { src: img_08 },
  { src: img_09 },
  { src: img_10 },
  { src: img_11 },
  { src: img_12 },
  { src: img_13 },
  { src: img_14 },
  { src: img_15 },
  { src: img_16 },
  { src: img_17 },
  { src: img_18 },
  { src: img_19 },
  { src: img_20 },
  { src: img_21 },
  { src: img_22 },
  { src: img_23 },
  { src: img_24 },
  { src: img_25 },
  { src: img_26 },
  { src: img_27 },
  { src: img_28 },
  { src: img_29 },
  { src: img_30 },
];


const GalleryComponent = () => {
  const navigate = useNavigate();


  const limitedPhotos = photos.slice(0, 16);

  const handleShowMoreClick = () => {
    
    window.scrollTo(0, 0);
    

    navigate('/full-gallery');
  };

  return (
    <div className="container mx-auto font-poppins py-8 px-4 md:px-8 lg:px-16 mt-8">
      <h1 className="font-poppins font-extrabold text-center text-3xl lg:text-4xl mb-10">
        Our Projects
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {limitedPhotos.map((photo, index) => (
          <div
            key={index}
            className="relative overflow-hidden group" 
          >
            <img
              src={photo.src}
              alt={`Gallery item ${index}`}
              className="w-full h-full object-cover transition-all duration-500 group-hover:scale-105" 
              style={{ maxHeight: '300px' }}
            />
            <div className="absolute inset-0 bg-gradient-to-r from-transparent to-white opacity-0 group-hover:opacity-30 transition-opacity duration-500"></div> {/* Shine effect */}
            <div className="absolute inset-0 shine-effect"></div> 
          </div>
        ))}
      </div>

      <div className="text-center mt-8">
        <button
          onClick={handleShowMoreClick} 
          className="bg-black w-max hover:bg-sandybrown text-white font-bold py-2 px-4 rounded"
        >
          Show More
        </button>
      </div>
    </div>
  );
};

export default GalleryComponent;

