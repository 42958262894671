const Servicecard = ({ image, servicename }) => {
  return (
    <div className=" font-poppins max-w-xs mx-auto bg-lightblack shadow-lg overflow-hidden relative card-container transition-transform transform hover:scale-105">
      <div className="relative w-96 h-56 group"> 
        <img src={image} alt={servicename} className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-gradient-to-r from-transparent to-white opacity-0 group-hover:opacity-30 transition-opacity duration-500"></div>
        <div className="absolute inset-0 shine-effect"></div>
      </div>
      <div className="p-4">
        <h3 className="text-lg font-semibold text-white">{servicename}</h3>
      </div>
    </div>
  );
};

export default Servicecard;
