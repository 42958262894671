import { useState } from "react";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import thumbnail1 from "../assets/images/Products/Book shelf/thumbnail1.jpg";
import prodbg from "../assets/images/ProDBg.jpg";
import img1 from "../assets/images/Products/Book shelf/img1.jpg";
import img2 from "../assets/images/Products/Book shelf/img2.jpg";
import img3 from "../assets/images/Products/Book shelf/img3.jpg";

// Chest of drawers
import thumbnail2 from "../assets/images/Products/Chest of drawers/thumbnail2.jpg";
import img4 from "../assets/images/Products/Chest of drawers/img4.jpg";
import img5 from "../assets/images/Products/Chest of drawers/img5.jpg";
import img6 from "../assets/images/Products/Chest of drawers/img6.jpg";
import img7 from "../assets/images/Products/Chest of drawers/img7.jpg";
import img8 from "../assets/images/Products/Chest of drawers/img8.jpg";
import img9 from "../assets/images/Products/Chest of drawers/img9.jpg";
import img10 from "../assets/images/Products/Chest of drawers/img10.jpg";
import img11 from "../assets/images/Products/Chest of drawers/img11.jpg";

// Crockery unit
import thumbnail3 from "../assets/images/Products/Crockery unit/thumbnail3.jpg";
import img12 from "../assets/images/Products/Crockery unit/img12.jpg";
import img13 from "../assets/images/Products/Crockery unit/img13.jpg";

// Dressing Unit
import thumbnail4 from "../assets/images/Products/Dressing Unit/thumbnail4.jpg";
import img14 from "../assets/images/Products/Dressing Unit/img14.jpg";
import img15 from "../assets/images/Products/Dressing Unit/img15.jpg";

// Foyer unit
import thumbnail5 from "../assets/images/Products/Foyer unit/thumbnail5.jpg";
import img16 from "../assets/images/Products/Foyer unit/img16.jpg";
import img17 from "../assets/images/Products/Foyer unit/img17.jpg";

// Kitchen Cabinet
import thumbnail6 from "../assets/images/Products/Kitchen Cabinet/thumbnail6.jpg";
import img18 from "../assets/images/Products/Kitchen Cabinet/img18.jpg";

// Kitchen Pantry
import thumbnail7 from "../assets/images/Products/Kitchen Pantry/thumbnail7.jpg";
import img19 from "../assets/images/Products/Kitchen Pantry/img19.jpg";
import img20 from "../assets/images/Products/Kitchen Pantry/img20.jpg";
import img21 from "../assets/images/Products/Kitchen Pantry/img21.jpg";

import thumbnail8 from "../assets/images/Products/Kitchen pantry unit/thumbnail8.jpg";
import img22 from "../assets/images/Products/Kitchen pantry unit/img22.jpg";
import img23 from "../assets/images/Products/Kitchen pantry unit/img23.jpg";
import img24 from "../assets/images/Products/Kitchen pantry unit/img24.jpg";
import img25 from "../assets/images/Products/Kitchen pantry unit/img25.jpg";
import img26 from "../assets/images/Products/Kitchen pantry unit/img26.jpg";
import img27 from "../assets/images/Products/Kitchen pantry unit/img27.jpg";
import img28 from "../assets/images/Products/Kitchen pantry unit/img28.jpg";
import img29 from "../assets/images/Products/Kitchen pantry unit/img29.jpg";



import thumbnail9 from "../assets/images/Products/Kitchen side by side pantry/thumbnail9.jpg";
import img35 from "../assets/images/Products/Kitchen side by side pantry/img35.jpg";
import img36 from "../assets/images/Products/Kitchen side by side pantry/img36.jpg";
import img37 from "../assets/images/Products/Kitchen side by side pantry/img37.jpg";

import thumbnail10 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/thumbnail10.jpg";
import img38 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/img38.jpg";
import img39 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/img39.jpg";
import img40 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/img40.jpg";
import img41 from "../assets/images/Products/Kitchen Wall Mounted Cabinet/img41.jpg";

import thumbnail11 from "../assets/images/Products/Multipurpose Shelf/thumbnail11.jpg";
import img42 from "../assets/images/Products/Multipurpose Shelf/img42.jpg";

// Pooja unit
import thumbnail12 from "../assets/images/Products/Pooja unit/thumbnail12.jpg";
import img43 from "../assets/images/Products/Pooja unit/img43.jpg";
import img44 from "../assets/images/Products/Pooja unit/img44.jpg";
import img45 from "../assets/images/Products/Pooja unit/img45.jpg";

// Shoe Rack
import thumbnail13 from "../assets/images/Products/Shoe Rack/thumbnail13.jpg";
import img46 from "../assets/images/Products/Shoe Rack/img46.jpg";
import img47 from "../assets/images/Products/Shoe Rack/img47.jpg";
import img48 from "../assets/images/Products/Shoe Rack/img48.jpg";
import img49 from "../assets/images/Products/Shoe Rack/img49.jpg";
import img50 from "../assets/images/Products/Shoe Rack/img50.jpg";
import img51 from "../assets/images/Products/Shoe Rack/img51.jpg";

// TV unit
import thumbnail14 from "../assets/images/Products/Tv unit/thumbnail14.jpg";
import img52 from "../assets/images/Products/Tv unit/img52.jpg";
import img53 from "../assets/images/Products/Tv unit/img53.jpg";
import img54 from "../assets/images/Products/Tv unit/img54.jpg";

// Wall floating shelves
import thumbnail15 from "../assets/images/Products/Wall floating shelves/thumbnail15.jpg";
import img55 from "../assets/images/Products/Wall floating shelves/img55.jpg";
import img56 from "../assets/images/Products/Wall floating shelves/img56.jpg";
import img57 from "../assets/images/Products/Wall floating shelves/img57.jpg";
import img58 from "../assets/images/Products/Wall floating shelves/img58.jpg";
import img59 from "../assets/images/Products/Wall floating shelves/img59.jpg";
import ContactFormModal from "./ContactModal";

const ProductDetails = () => {
    const products = [
      {
        prodname: "Book Shelf",
        thumbnail: thumbnail1,
        images: [img1, img2, img3, img4],
        content: {
          description : "Book Shelf is a free standing furniture unit with multiple horizontal stack of racks or shelves which are used for varied purposes to store your books or display curios. Some have a back panel or could be open from both sides.",
          dimensions : "H 65 x W 31 x D 11",
          material: "Plywood",
          room_type : "Study Room"
        },
      },
      {
        prodname: "Chest of Drawers",
        thumbnail: thumbnail2,
        images: [img5, img6, img7, img8, img9, img10, img11],
        content : {
          description: "If you want to organise inside you can complement with SKUBB box, set of 6. Smooth running drawers with pull-out stops. Your home should be a safe place for the entire family. That’s why a wall-anchoring kit is included for you to anchor your chest of drawers to the wall.",
          material: "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care: "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
        prodname: "Crockery Unit",
        thumbnail: thumbnail3,
        images: [img12, img13],
        content: {
           description : "Keep your kitchen essentials accessible and organized with this Crockery unit. Modern style will easily fit in with any style room decor. Open storage space and closed storage compartments offer plenty of space to accommodate your kitchen essentials organized. The countertop is perfect to keep your microwave and other small appliances handy. Simplify and organize your kitchen utensils and get your valuable space back.",
           material : "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
           care : "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
        prodname: "Dressing Unit",
        thumbnail: thumbnail4,
        images: [img14, img15],
        content : {
          description : "Large Top and Plenty Leg Space: Top offers ample space for your make up essentials and even works great as a desktop for home office and more. This product size will fit perfectly in your bedroom.",
          dimensions: "57.88” H x 47.25” W x 18.31” D.",
          features: "Lighted Mirror: 14 Hollywood light bulbs add a sense of glamor and style.",
          material: "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care: "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
        prodname: "Foyer Unit",
        thumbnail: thumbnail5,
        images: [img16, img17],
        content: {
          description : "If you want to organise inside you can complement with this foyer unit, set of 6. Smooth running drawers with pull-out stops. Your home should be a safe place for the entire family. That’s why a wall-anchoring kit is included for you to anchor your chest of drawers to the wall.",
          material: "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care: "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
        prodname: "Kitchen Cabinet",
        thumbnail: thumbnail6,
        images: [img18],
        content : {
          description : "Made from MDF/Plywood: This storage cabinet is constructed from high-quality MDF/Plywood with laminated finish, ensuring durability and strength for everyday use.",
          material: "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care: "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
        prodname: "Kitchen Pantry",
        thumbnail: thumbnail7,
        images: [img19, img20, img21],
        content : {
          description : "Lend your space some extra storage with this Kitchen Pantry. Made in India, this cabinet strikes a tall rectangular silhouette crafted of medium-density fiberboard in a crisp white finish, while four tubular legs provide sturdy support. One full-length door opens to reveal four spacious shelves – perfect for storing everything from towels, bed linens, and laundry supplies, to books, binders, and office accessories.",
          dimensions : "58.88\" H x 16.41\" W x 12.2\" D",
          material : "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care : "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
        prodname: "Kitchen Pantry Unit",
        thumbnail: thumbnail8,
        images: [img22, img23, img24, img25, img26, img27, img28, img29],
        content : {
          description : "Streamline your space with the multi-storage cabinet. Perfect for use in the kitchen, bedroom, living room, office, or any other room that would need a large storage unit. With five shelves that sit neatly behind two sleek doors. Features: Tall, space-efficient for different spaces, get a ton of storage for your bathroom, kitchen, bedroom, or garage.",
          material : "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care : "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
        prodname: "Kitchen Side by Side Pantry",
        thumbnail: thumbnail9,
        images: [img35, img36, img37],
        description: {
          description : "Clean lines and stylish design add a contemporary touch to your home and kitchen. It is perfect for storing and displaying your daily items. The pantry cabinet is a great storage choice for compact space.",
          dimensions: "40.7'' H x 23.2'' W x 12'' D",
          material: "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care: "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
            prodname: "Kitchen Wall Mounted Cabinet",
            thumbnail: thumbnail10,
            images: [img38, img39, img40, img41],
          },
          {
            prodname: "Multipurpose Shelf",
            thumbnail: thumbnail11,
            images: [img42],
          },
      {
        prodname: "Pooja Unit",
        thumbnail: thumbnail12,
        images: [img43, img44, img45],
        content : {
          description: "A powerful prayer always spreads happiness at home. Make your home filled with divine and devotion with the pooja unit. This pooja unit is carved with a touch of tradition to influence the Indian way of worship. It is made up of the best quality board. It consists of storage units to store prayer equipment and accessories.",
          dimensions: "3ft * 6ft",
          material: "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care: "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth."
        },
      },
      {
        prodname: "Shoe Rack",
        thumbnail: thumbnail13,
        images: [img46, img47, img48, img49, img50, img51],
        content : {
          description : "This simple yet stylish shoe rack cabinet is the perfect combination of function, versatility, and style. This multipurpose shoe rack shelf is the perfect entryway or closet companion to keep your footwear in order. Consider a larger option if you’re looking to store large shoes.",
          material : "MDF/Plywood, Lamination.",
          features : "Multiple Storage shelf | Door with Hinges & Handle | Lightweight & Portable | Easy to install Features four shelves, including a top open shelf, providing ample storage space.",
          dimensions : "Dimensions can be customized."
        },
      },
      {
        prodname: "TV Unit",
        thumbnail: thumbnail14,
        images: [img52, img53, img54],
        content : {
          description : "Defined by its glossy finish and clean-lined design, this Tv Console instantly elevates your entertainment area in a sophisticated style. Its hue color blends effortlessly into both monochromatic or vibrant spaces, while its rectangle silhouette is a perfect fit for any casual space.",
          features: "Drawers Included. Number of Drawers: 2.",
          material: "Top panel/ Side panel/ Drawer front/ Plinth front/ Front rail/ Back/Drawer Bottom: MDF/Plywood, Laminate.",
          care: "Wipe clean with a cloth dampened in a mild cleaner. Wipe dry with a clean cloth.",
          dimensions: "Dimensions can be customized."
        },
      },
      {
        prodname: "Wall Floating Shelves",
        thumbnail: thumbnail15,
        images: [img55, img56, img57, img58, img59],
       content : {
        description : "Unique and Exquisite Vanity Design - The new style floating shelves adopt the concept of contemporary decorating to polish each sturdy MDF perfectly beautiful. With the special golden metal bracket, bring you a noble and luxurious home style.",
        features: "Each floating wall shelf's maxed weight capacity is up to 40 lb.",
        material: "Wider Triangle Gold Metal Brackets - Three different wood board sizes are Large: 15.74inX6.1in; Medium: 13.77inX6.1in; Small:11.41inX6.1in with same holder height 6.1in and 0.6in thickness.",
        dimensions : "Overall - 0.6'' H x 6.1'' W x 15.74'' D Shelf - 6.1'' W x 15.74'' D",
        dimensions_customized : "Dimensions can be customized."
      }
      }
    ];

    const location = useLocation();
    const { productName } = location.state || {};

    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const product = products.find((p) => p.prodname === productName);

    if (!productName || !product) {
      return <div>Product not found</div>;
    }

    if (!selectedImage) {
      setSelectedImage(product.images[0]);
    }
    
      return (
        <div className="relative font-poppins flex max-sm:flex-col pt-6 px-4 md:px-16 h-auto mt-5 bg-gradient-to-l py-20 shadow-lg overflow-hidden max-sm:py-10">
          
          <div className="absolute inset-0">
            <img src={prodbg} className="absolute inset-0 w-full h-full object-cover" alt="Background" />
            <div className="absolute inset-0 bg-black opacity-60"></div>
          </div>

          
        
          <div className="flex flex-col">
          <div className="relative z-10 mt-12 mb-6">
            <nav className="text-white text-sm font-medium">
              <ul className="flex items-center space-x-2">
                <li>
                  <Link to="/" className="hover:underline">
                    Home
                  </Link>
                </li>
                <li>{`>`}</li>
                <li>
                  <Link to="/all-products" className="hover:underline">
                    Products
                  </Link>
                </li>
                <li>{`>`}</li>
                <li>
                  <span className="text-gray-300">
                    {product.prodname}
                  </span>
                </li>
              </ul>
            </nav>
          </div>
          <div className="relative flex flex-col md:flex-row gap-6">
            <div className="thumbnails w-24 flex flex-col max-sm:flex-row gap-4">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  className={`w-24 h-12 object-cover rounded cursor-pointer ${
                    selectedImage === image ? "border-blue border-4" : "border-white border-2"
                  }`}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
            </div>

            <div className="main-image rounded-xl flex-1 h-[550px] w-auto border-2 bg-white flex items-center justify-center overflow-hidden">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Main product"
                  className="w-full h-full object-contain"
                />
              ) : (
                <p className="text-white">Select an image</p>
              )}
            </div>

            <div className="flex-1 text-justify rounded-lg bg-white text-black p-10">
              <h1 className="text-sandybrown text-3xl font-bold mb-2">{product.prodname}</h1>
        
              {product.content.description && (
                <>
                  <h3 className="font-semibold text-md mt-2 mb-1">Description</h3>
                  <p className="text-sm">{product.content.description}</p>
                </>
              )}
        
              {product.content.dimensions && (
                <>
                  <h3 className="font-semibold text-md mt-4 mb-1">Dimensions</h3>
                  <p className="text-sm">{product.content.dimensions}</p>
                </>
              )}
        
              {product.content.material && (
                <>
                  <h3 className="font-semibold text-md mt-4 mb-1">Material</h3>
                  <p className="text-sm">{product.content.material}</p>
                </>
              )}
        
              {product.content.room_type && (
                <>
                  <h3 className="font-semibold text-md mt-4 mb-1">Room Type</h3>
                  <p className="text-sm">{product.content.room_type}</p>
                </>
              )}
        
              {product.content.care && (
                <>
                  <h3 className="font-semibold text-md mt-4 mb-1">Care Instructions</h3>
                  <p className="text-sm">{product.content.care}</p>
                </>
              )}
        
              {product.content.features && (
                <>
                  <h3 className="font-semibold text-md mt-4 mb-1">Special Features</h3>
                  <p className="text-sm">{product.content.features}</p>
                </>
              )}
        
              <button
                className="bg-slateblue text-white p-3 rounded-md hover:bg-gray-800 transition-colors mt-6"
                onClick={() => setIsModalOpen(true)}
              >
                Enquire about the product
              </button>
            </div>
          </div>
          </div>
        
          {isModalOpen && (
            <ContactFormModal 
              setIsModalOpen={setIsModalOpen} 
              prodimage={selectedImage} 
              prodname={product.prodname} 
            />
          )}
        </div>
      );
    };
    
    export default ProductDetails;
    