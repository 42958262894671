import { FaLaptop, FaFileInvoice, FaHandshake, FaCalendarCheck, FaPaintRoller, FaClipboardCheck, FaHammer } from 'react-icons/fa';

const HowWeWork = () => {
  return (
    <section className="bg-lightblack font-poppins py-16 px-4 sm:px-6 lg:px-16" >
      <div className="max-w-7xl mx-auto">
        <div className="text-left">
          <h1 className="font-poppins text-white font-extrabold text-left max-sm:text-center text-3xl lg:text-4xl mb-2" data-dos="fade-right">
            How Do We Work ?
          </h1>
          <p className="mt-4 text-lg font-poppins text-left text-white max-sm:text-center max-w-2xl" data-dos="fade-right">
            Our process ensures your vision is brought to life with precision and care.
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8" data-dos="fade-up">
          <div className="bg-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-xl flex flex-col items-center text-center" >
            <FaLaptop className="text-slateblue text-4xl mb-4" />
            <h3 className="text-lg leading-6 font-medium text-midnightblue">1) Online Consultation</h3>
            <p className="mt-2 text-base text-gray-500">
              Telephone conversation to understand your dream and vision.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-xl flex flex-col items-center text-center">
            <FaFileInvoice className="text-slateblue text-4xl mb-4" />
            <h3 className="text-lg leading-6 font-medium text-midnightblue">2) First Quotation</h3>
            <p className="mt-2 text-base text-gray-500">
              Estimate to give you an overview of the finances.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-xl flex flex-col items-center text-center">
            <FaHandshake className="text-slateblue text-4xl mb-4" />
            <h3 className="text-lg leading-6 font-medium text-midnightblue">3) In-Person Discussion</h3>
            <p className="mt-2 text-base text-gray-500">
              Elaborate discussion on designs, materials, and execution.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-xl flex flex-col items-center text-center">
            <FaCalendarCheck className="text-slateblue text-4xl mb-4" />
            <h3 className="text-lg leading-6 font-medium text-midnightblue">4) Advance Booking</h3>
            <p className="mt-2 text-base text-gray-500">
              10% advance to get started with 2D and 3D designs.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-xl flex flex-col items-center text-center">
            <FaPaintRoller className="text-slateblue text-4xl mb-4" />
            <h3 className="text-lg leading-6 font-medium text-midnightblue">5) Material Selection</h3>
            <p className="mt-2 text-base text-gray-500">
              Selecting the suitable colors, themes, and materials for the project.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-xl flex flex-col items-center text-center">
            <FaClipboardCheck className="text-slateblue text-4xl mb-4" />
            <h3 className="text-lg leading-6 font-medium text-midnightblue">6) Final Quotation</h3>
            <p className="mt-2 text-base text-gray-500">
              Approval of designs and final quotation sharing.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-xl flex flex-col items-center text-center">
            <FaHammer className="text-slateblue text-4xl mb-4" />
            <h3 className="text-lg leading-6 font-medium text-midnightblue">7) Execution Begins</h3>
            <p className="mt-2 text-base text-gray-500">
              Full-fledged execution of the approved designs in 30 working days.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
