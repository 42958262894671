import { useState, useEffect, useRef } from 'react';
import img1 from '../assets/images/img1.png';
import img2 from '../assets/images/img2.png';
import img3 from '../assets/images/img3.png';
import img4 from '../assets/images/img4.png';
import HeroSlider from '../components/HeroSlider';
// import walltint from "../assets/images/walltint.png"

const NewHero = () => {
  const contentData = [
    {
      image: img1,
      color: 'black', 
      heading: 'Premium Materials',
      text: 'Elevate your space with premium materials that blend luxury and durability, creating interiors that stand the test of time. Discover the essence of refined design in every detail.'
    },
    {
      image: img2,
      color: 'black', 
      heading: '10 Years Warranty',
      text: 'Enjoy peace of mind with our 10-year warranty, ensuring your interiors maintain their premium quality and craftsmanship for years to come.'
    },
    {
      image: img3,
      color: 'black', 
      heading: 'Completion in 30 Working Days',
      text: 'Transform your space swiftly with our efficient process, delivering beautifully designed interiors in just 30 working days.'
    },
    {
      image: img4,
      color: 'black', 
      heading: 'Life-long Service Support',
      text: 'Experience unmatched reliability with our lifetime service support, keeping your interiors in perfect condition long after completion.'
    }
  ];

  const [currentContent, setCurrentContent] = useState(contentData[0]);
  const [transition, setTransition] = useState(false);

  const intervalRef = useRef(null);

  useEffect(() => {
    const rotateContent = () => {
      const currentIndex = contentData.findIndex(content => content.image === currentContent.image);
      const nextIndex = (currentIndex + 1) % contentData.length;
      setTransition(true);
      setTimeout(() => {
        setCurrentContent(contentData[nextIndex]);
        setTransition(false);
      }, 1000); 
    };

    intervalRef.current = setInterval(rotateContent, 3000);

    return () => clearInterval(intervalRef.current);
  }, [currentContent]);

  return (
      <div className="relative px-16 h-auto py-10 w-full font-poppins overflow-hidden bg-gradient-to-r from-white via-slate-100 to-slate-400 flex flex-col justify-between">
        <div className='flex flex-col lg:flex-row justify-center items-center md:h-[70vh] gap-4 px-4 md:px-16 mt-5 max-md:mt-20 max-lg:mt-20 max-sm:mt-32'>
          <div className='flex flex-col justify-center items-center lg:items-start text-center lg:text-left w-full lg:w-1/2 space-y-4'>
            <div>
            <h1 className='font-poppins font-bold text-3xl sm:text-4xl md:text-5xl text-yellow-500'>
              Crafting Dreams
            </h1>
            <h1 className='font-poppins text-black font-extrabold text-2xl sm:text-3xl'>
              Your Vision, Our Design
            </h1>
            </div>
            <div className="flex gap-4 justify-center lg:justify-start">
            <button
              onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
              className="border-2 font-bold max-sm:text-sm max-sm:h-10 max-sm:px-5 max-sm:w-full border-yellow-500 text-black rounded-lg p-2 sm:px-8 px-4 cursor-pointer hover:bg-yellow-500 hover:text-white transition duration-300 ease-in-out whitespace-nowrap"
            >
              Contact Us
            </button>
            <button
              onClick={() => document.getElementById('products').scrollIntoView({ behavior: 'smooth' })}
              className="border-2 font-bold max-sm:text-sm max-sm:px-5 max-sm:h-10 max-sm:w-auto border-yellow-500 text-black rounded-lg p-2 sm:px-8 px-4 cursor-pointer hover:bg-yellow-500 hover:text-white transition duration-300 ease-in-out whitespace-nowrap"
            >
              Our Products
            </button>
          </div>
          </div>

          <div className='w-full lg:w-1/2 h-64 sm:h-80 md:h-96 rounded-lg'>
            <HeroSlider />
          </div>
        </div>

        <div 
          className="bottom-0 left-0 w-full mt-2 h-[20%] flex flex-col justify-center bg-transparent text-black px-4 sm:px-8 md:px-16 lg:px-32"
        >
          <h2 
            className={`text-lg sm:text-2xl text-center md:text-4xl font-poppins font-bold ${transition ? 'animate-slideOutLeft' : 'animate-slideInLeft'}`} 
            style={{ color: currentContent.color }}
          >
            {currentContent.heading}
          </h2>
          <p 
            style={{ color: currentContent.color }} 
            className={`mt-2 sm:mt-4 font-poppins leading-normal text-center text-sm sm:text-base md:text-xl ${transition ? 'animate-slideOutRight' : 'animate-slideInRight'}`}
          >
            {currentContent.text} 
          </p>
        </div>
      </div>

  );
};

export default NewHero;
