import { useNavigate } from "react-router-dom";

const Prodcard = ({ prodname, thumbnail }) => {
  const navigate = useNavigate();
  const dataToPass = { productName: prodname }; 

  const handleViewProductClick = () => {
    
    window.scrollTo(0, 0);
    
   
    navigate("/product-details", { state: dataToPass });
  };

  return (
    <div className="prodcard font-poppins bg-white p-4 shadow-lg rounded-lg">
      <h2 className="text-md font-semibold mb-4">{prodname}</h2>

      <div className="thumbnail mb-4">
        <img
          src={thumbnail}
          alt={`${prodname} thumbnail`}
          className="w-full h-48 rounded object-cover cursor-pointer"
        />
      </div>
      <div className="flex gap-2 items-center justify-center">
        <button
          onClick={handleViewProductClick} 
          className="bg-slateblue w-full text-white text-center text-sm px-3 py-2 rounded hover:bg-blue-600 transition duration-300"
        >
          View Product
        </button>
      </div>
    </div>
  );
};

export default Prodcard;
